
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        















































































































































































































.list__item {
  &:not(:last-child) {
    border-bottom: 1px solid $c-gray-lighter;
  }
}

.list__item__picto {
  @include fluid(
    width,
    (
      xxs: 36px,
      xxl: 50px,
    )
  );
  @include fluid(
    height,
    (
      xxs: 36px,
      xxl: 50px,
    )
  );
  @include fluid(
    margin-right,
    (
      xxs: 10px,
      xxl: 20px,
    )
  );

  flex-shrink: 0;
  fill: $c-orange;
}

.list__item__address {
  line-height: 1;
}

.list__item__links {
  > * {
    margin: 0.5rem;
  }
}

.simulation {
  padding: 1em 1em 1em 2em;
  border-radius: $border-radius;
  border: 1px solid $c-gray-lighter;

  .text-orange {
    color: $c-orange;
  }

  .simulation__picto {
    flex-basis: 8%;
    max-width: 8%;
  }
}

.error {
  background-color: $c-bg-error;
  padding: 25px;
  margin-bottom: 30px;
  color: $c-red;
  border-radius: 20px;

  ::v-deep {
    .close-link {
      float: right;
      cursor: pointer;
    }

    svg {
      fill: $c-red;
    }
  }
}

.alert-success {
  margin-top: 30px;
  color: #3e7a6b;
  background-color: #f5f8f7;
  display: flex;
  padding: 15px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 0;

  .icon {
    fill: $c-dark-green;
  }
}

.alert-danger {
  margin-top: 30px;
  color: $c-orange;
  background-color: $c-orange-lighter;
  display: flex;
  padding: 15px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 0;

  .icon {
    fill: $c-orange;
  }
}
